@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  background: #f0f2f5;
  overflow-x: auto !important;
}

li.ant-menu-item.ant-menu-item-selected {
  background: none !important;
  background: #f37021 !important;
  color: #fff !important;
  width: 96% !important;
  margin: auto;
}
li.ant-menu-item {
  color: #fff !important;
  background: none !important;
}
li.ant-menu-item:hover {
  color: none;
}
li.ant-menu-item {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  padding-left: 44px !important;
  line-height: 10px;
}

nav.ant-breadcrumb.css-dev-only-do-not-override-htwhyh {
  font-style: italic !important;
  font-size: 22px;
}
.ant-layout-sider-children {
  overflow-y: hidden !important;
}
.fixed-menu {
  position: fixed !important;
  z-index: 9999;
  height: 100% !important;
  padding-top: 100px;
  overflow: auto;
}
main.ant-layout-content.css-dev-only-do-not-override-htwhyh {
  padding: 30px 30px 30px 35px;
}
aside.ant-layout-sider.ant-layout-sider-dark {
  z-index: 99999;
}
.your-element {
  overflow-x: auto;
  width: 100%;
  height: 100vh;
}
.your-element::-webkit-scrollbar {
  overflow-y: hidden;
}

/* upload ant */
.ant-upload.ant-upload-select {
  display: block !important;
}
/* padding select ant */ 
.ant-select-selector {
  height: 40px !important;
}
span.ant-select-selection-placeholder,
span.ant-select-selection-item  {
  margin-top: 4px !important;
}

/* style tabs ant */

.ant-tabs-nav-wrap {
  padding-left: 2% !important;
}

.ant-tabs-nav {
  font-size: 18px;
  border-top: 1px solid #eee;
  margin-top: 8px !important;
  border-top: 1px solid #eee;
}
.ant-tabs-tab {
  font-size: 18px !important;
}
.ant-tabs-tab:hover {
  color: #2CBDB3 !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #2CBDB3 !important;
}

/* ghi đè css customer Steps */ 
:where(.css-dev-only-do-not-override-htwhyh).ant-steps .ant-steps-item-finish .ant-steps-item-icon >.ant-steps-icon .ant-steps-icon-dot,
:where(.css-dev-only-do-not-override-htwhyh).ant-steps .ant-steps-item-process .ant-steps-item-icon >.ant-steps-icon .ant-steps-icon-dot{
  background-color: #35A0B8 !important;
  width: 17px;
  height: 17px;
}
:where(.css-dev-only-do-not-override-htwhyh).ant-steps .ant-steps-item-wait .ant-steps-item-icon >.ant-steps-icon .ant-steps-icon-dot {
  width: 17px;
  height: 17px;
  background: rgba(0, 0, 0, 0.25);
}

:where(.css-dev-only-do-not-override-htwhyh).ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
  background-color: #35A0B8 !important;
  margin-top: 5px;
  margin-left: 16px;
}
:where(.css-dev-only-do-not-override-htwhyh).ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-tail::after,
:where(.css-dev-only-do-not-override-htwhyh).ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-tail::after {
  background: rgba(0, 0, 0, 0.25) !important;
  margin-top: 5px;
  margin-left: 16px;
}

/*important hover paganition*/
.ant-pagination-item:hover {
    border-color: #f37021 !important;
}
.ant-pagination-item-active a,
.ant-pagination-item a:hover {
    background-color: #f37021 !important;
    color: #fff !important;
}
.ant-pagination-item-active {
    border-color: #f37021 !important;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link:hover {
    border-color: #61c7b5 !important;
} 
/*  */
span#prioritize {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 0px !important;
}

.ant-notification.ant-notification-topRight.css-dev-only-do-not-override-htwhyh {
  z-index: 9999;
}

span.anticon.anticon-close.ant-tag-close-icon {
  padding-left: 25px;
}
/* remove focus teare */

:where(.css-dev-only-do-not-override-htwhyh).ant-input:focus {
  box-shadow: none !important;
}

/* style chart */
.g2-html-annotation {
  margin-top: -20px;
}

.ant-breadcrumb {
  font-size: 14px !important;
}

.ck-editor__editable_inline {
  min-height: 500px; /* Chiều cao tối thiểu */
  height: 500px;     /* Chiều cao cụ thể */
  max-height: 500px; /* Chiều cao tối đa */
  overflow-y: auto;  /* Hiển thị thanh cuộn nếu vượt quá chiều cao tối đa */
}

.ck-content p {
  line-height: 1.5; /* Điều chỉnh khoảng cách dòng */
  margin: 0; /* Loại bỏ khoảng cách margin */
}

.ck-content .font-size-small { font-size: 12px; }
.ck-content .font-size-default { font-size: 16px; }
.ck-content .font-size-big { font-size: 20px; }
.ck-content .font-size-huge { font-size: 24px; }

aside.ant-layout-sider.ant-layout-sider-dark {
  padding-top: 25px !important;
}
main.ant-layout-content.css-htwhyh {
  padding: 30px 30px 30px 35px !important;
}

@media (max-width: 1024px) {
  main.ant-layout-content.css-dev-only-do-not-override-htwhyh {
    width: 1300px;
  }
}